import { configureStore } from "@reduxjs/toolkit"
import storage from "redux-persist/lib/storage"
import { persistReducer, persistStore } from "redux-persist"
import thunk from "redux-thunk"
import loginReducer from "../pages/loginPage/loginSlice"
import routesReducer from "../features/routes/routesSlice"
import stationsReducer from "../features/stations/stationsSlice"
import driverMapReducer from "../features/driverMap/driverMapSlice"
import mainPageReducer from "../pages/mainPage/mainPageSlice"
import ordersReducer from "../features//orders/ordersSlice"
import barcodeScannerReducer from "../features/barcodeScanner/barcodeScannerSlice"
import stationTaskReducer from "../features/orders/stationTask/stationTaskSlice"
import { getPersistConfig } from "redux-deep-persist"
import { combineReducers } from "redux"

const combinedReducer = combineReducers({
  login: loginReducer,
  routes: routesReducer,
  stations: stationsReducer,
  driverMap: driverMapReducer,
  main: mainPageReducer,
  orders: ordersReducer,
  barcodeScanner: barcodeScannerReducer,
  stationTask: stationTaskReducer,
})

const rootReducer = (state, action) => {
  if (action.type === "login/clearUserData") {
    state = undefined
  }
  return combinedReducer(state, action)
}

const config = getPersistConfig({
  key: "root",
  storage,
  blacklist: [
    "stations.loading",
    "stations.isSesionExpired",
    "orders.loading",
    "routes.loading",
    "login.loading",
    "login.loginFail",
    "login.getUSerSuccess",
    "login.loginSuccess",
    "barcodeScanner",
  ],
  rootReducer,
})

const persistedReducer = persistReducer(config, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
})

export const persistor = persistStore(store)
