import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Routes } from "../../features/routes/routes.js"
import { Stations } from "../../features/stations/stations.js"
import { DriverMap } from "../../features/driverMap/driverMap.js"
import { getCurrentLocation } from "../../utils/location.js"
import { getCurrentIsraelTimeUnixFormat } from "../../utils/formatDate.js"
import { getStationsByRoute } from "../../features/stations/stationsSlice"
import { Header } from "../../features/common/header/header.js"
import { Orders } from "../../features/orders/orders.js"
import { setCurrentLocation } from "../../features/driverMap/driverMapSlice"
import { useNavigate } from "react-router-dom"
import { ButtonWhite } from "../../features/common/buttonWhite/buttonWhite"
import { NotificationPopup } from "../../features/common/notificationPopup/notificationPopup"
import { BarcodeScanner } from "../../features/barcodeScanner/barcodeScanner"
import { endDriverRouteSessions } from "../../features/routes/routesSlice"
import truck from "../../assets/images/truck.png"
import "./mainPage.scss"

export function MainPage() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { showStations, orderDetailsClicked, isSesionExpired } = useSelector(
    (state) => state.stations
  )
  const { showDriverMap } = useSelector((state) => state.driverMap)
  const { showOrders } = useSelector((state) => state.orders)
  const { userData, userloginData } = useSelector((state) => state.login)
  const { testLatitude, testLongitude, startDayTime } = useSelector(
    (state) => state.main
  )
  const { showRoutes, driverCurrentRoute, selectedRouteId } = useSelector(
    (state) => state.routes
  )
  const { showScanModal } = useSelector((state) => state.barcodeScanner)
  const [isRefreshRouteBtnDisabled, setRefreshRouteBtnDisabled] =
    useState(false)

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(getPosition)
  }, [])

  useEffect(() => {
    if (orderDetailsClicked) {
      refreshStationsByRoute()
    }
  }, [orderDetailsClicked])

  useEffect(() => {
    if (userData == null) {
      navigate("/")
    }
  }, userData)

  useEffect(() => {
    if (driverCurrentRoute !== null) {
      const refreshStationsInterval = setInterval(
        refreshStationsByRoute,
        process.env.REACT_APP_ROUTES_REFRESH_INTERVAL
      )
      return () => {
        clearInterval(refreshStationsInterval)
      }
    }
  }, [driverCurrentRoute, selectedRouteId, testLatitude, testLongitude])

  useEffect(() => {
    const getLocationInterval = setInterval(() => {
      if (testLatitude && testLongitude) {
        dispatch(
          setCurrentLocation({
            latitude: testLatitude,
            longitude: testLongitude,
          })
        )
      } else {
        navigator.geolocation.getCurrentPosition(getPosition)
      }
    }, 10000)
    return () => {
      clearInterval(getLocationInterval)
    }
  }, [testLatitude, testLongitude])

  const getPosition = (position) => {
    dispatch(
      setCurrentLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      })
    )
  }

  const refreshStationsByRouteClicked = () => {
    setRefreshRouteBtnDisabled(true)
    if (navigator.onLine) {
      refreshStationsByRoute()
    }
    setTimeout(() => setRefreshRouteBtnDisabled(false), 10000)
  }

  const refreshStationsByRoute = () => {
    if (testLatitude && testLongitude) {
      dispatch(
        getStationsByRoute({
          token: userloginData.token,
          routeId: selectedRouteId,
          useStationRouteCache: true,
          latitude: testLatitude,
          longitude: testLongitude,
          startedDayTime: startDayTime
            ? startDayTime
            : getCurrentIsraelTimeUnixFormat(),
        })
      )
    } else {
      getCurrentLocation()
        .then((res) => {
          dispatch(
            getStationsByRoute({
              token: userloginData.token,
              routeId: selectedRouteId,
              useStationRouteCache: true,
              latitude: res.coords.latitude,
              longitude: res.coords.longitude,
              startedDayTime: startDayTime
                ? startDayTime
                : getCurrentIsraelTimeUnixFormat(),
            })
          )
        })
        .catch(() => {
          console.log("error refresh routes")
        })
    }
  }

  const sessionExpiredHandler = () => {
    dispatch(
      endDriverRouteSessions({
        token: userloginData.token,
        routeId: selectedRouteId,
      })
    )
  }

  return (
    <div className="mainPage-wrapper ">
      <Header />
      {showDriverMap ? (
        <ButtonWhite
          disabled={isRefreshRouteBtnDisabled}
          extraclass={
            "btn-refresh-routes" +
            (isRefreshRouteBtnDisabled ? " btn-refresh-disabled" : "")
          }
          text="רענן תחנות"
          clickHandler={() => refreshStationsByRouteClicked()}
        />
      ) : null}

      <div
        className={`mainPage-container ${
          showDriverMap ? "vertical" : "horizontal"
        }  `}
      >
        {showRoutes ? <Routes /> : null}
        {showStations ? (
          <Stations />
        ) : (
          <div className="parallax">
            <img src={truck} class="truck" alt="truck"></img>
          </div>
        )}
        {showDriverMap ? <DriverMap /> : null}
        {showOrders ? <Orders /> : null}
        {showScanModal ? <BarcodeScanner /> : null}
        <NotificationPopup
          show={isSesionExpired}
          text={"session expired"}
          close={() => sessionExpiredHandler()}
        ></NotificationPopup>
      </div>
    </div>
  )
}
