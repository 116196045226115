import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const getOrdersByStation = createAsyncThunk(
  "orders/getDriverOrdersByStation",
  async (params, { rejectWithValue }) => {
    try {
      let startTime = params.startDayTime
      if (!params.startDayTime) {
        startTime = 0
      }
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_KEY}/Orders/getDriverOrdersByStation/${params.stationId}/${startTime}`,
        {
          headers: {
            Authorization: `Bearer ${params.token}`,
          },
        }
      )
      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const getOrdersTypes = createAsyncThunk(
  "orders/getOrdersTypes",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_KEY}/Orders/order_types`,
        {
          headers: {
            Authorization: `Bearer ${params.token}`,
          },
        }
      )
      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const getAccountTypes = createAsyncThunk(
  "orders/getAccountTypes",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_KEY}/Orders/account_types`,
        {
          headers: {
            Authorization: `Bearer ${params.token}`,
          },
        }
      )
      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const ordersSlice = createSlice({
  name: "orders",
  initialState: {
    ordersList: [],
    showOrders: false,
    loading: false,
    orderTypes: [],
    accountTypes: [],
  },
  reducers: {
    setShowOrders: (state, action) => {
      state.showOrders = action.payload
    },

    clearOrdersData: (state) => {
      state.ordersList = []
    },
  },
  extraReducers: {
    [getOrdersByStation.pending]: (state) => {
      state.loading = true
    },
    [getOrdersByStation.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.ordersList = payload
    },
    [getOrdersByStation.rejected]: (state) => {
      state.loading = false
    },
    [getOrdersTypes.pending]: (state) => {
      state.loading = true
    },
    [getOrdersTypes.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.orderTypes = payload
    },
    [getOrdersTypes.rejected]: (state) => {
      state.loading = false
    },
    [getAccountTypes.pending]: (state) => {
      state.loading = true
    },
    [getAccountTypes.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.accountTypes = payload
    },
    [getAccountTypes.rejected]: (state) => {
      state.loading = false
    },
  },
})

export const { setShowOrders, clearOrdersData } = ordersSlice.actions

export default ordersSlice.reducer
