export const getFormatedDate = (dateStr) => {
  if (dateStr == null) return ""
  const dateObj = new Date(dateStr)
  dateObj.setTime(dateObj.getTime() + 2 * 60 * 60 * 1000)

  const result = dateObj
    .toLocaleString("he-IL", { timeZone: "Israel" })
    .replace(/\,/g, ",")
    .split(":", 2)
    .join(":")
  return result
}

export const getCurrentIsraelTimeUnixFormat = () => {
  const currentTime = new Date()
  const israelTimeZoneOffset = 3 * 60
  const israelTime = new Date(
    currentTime.getTime() + israelTimeZoneOffset * 60000
  )
  const israelTimeUnixFormat = Math.floor(israelTime.getTime() / 1000)
  return israelTimeUnixFormat
}
