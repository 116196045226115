import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { loginUser, getUserData } from "./loginSlice"
import logo from "../../assets/images/done_logo.svg"
import { Spinner } from "../../features/common/spinner/Spinner.js"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { validationSchema } from "../../utils/validation.js"
import { useNavigate } from "react-router-dom"
import { clearUserData } from "./loginSlice.js"
import "./login.scss"

export function Login() {
  const dispatch = useDispatch()
  const { loading, loginSuccess, loginFail, userloginData, getUSerSuccess } =
    useSelector((state) => state.login)
  const navigate = useNavigate()
  const formOptions = { resolver: yupResolver(validationSchema) }
  const [errorMsg, setErrorMsg] = useState("")

  useEffect(() => {
    if (loginFail) {
      setErrorMsg("תקלה")
    }
  }, [loginFail])

  useEffect(() => {
    if (loginSuccess) {
      dispatch(getUserData(userloginData.token))
    }
  }, [loginSuccess])

  useEffect(() => {
    if (getUSerSuccess) {
      if (
        userloginData.userType == "Manager" ||
        userloginData.userType == "DoneDriver" ||
        userloginData.userType == "Driver"
      ) {
        navigate("/MainPage")
      }
      setErrorMsg("אינך מורשה להיכנס למערכת")
    }
  }, [getUSerSuccess])

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions)

  const onSubmit = (data) => {
    dispatch(loginUser(data))
  }

  return (
    <div className="login-wrapper">
      <div className="enter-text">
        <div>כניסה</div>
        <div className="underline"></div>
      </div>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <div className="inputs-wrapper">
          <div className="input-container">
            <div className="input-label">נייד</div>
            <input
              name="mobilePhone"
              maxLength={10}
              {...register("mobilePhone")}
            ></input>
            <span className="error-text">{errors.mobilePhone?.message}</span>
          </div>
          <div className="input-container">
            <div className="input-label">סיסמה</div>
            <input
              type="password"
              name="password"
              {...register("password")}
            ></input>
            <span className="error-text">{errors.password?.message}</span>
          </div>
        </div>
        {errorMsg ? <div className="login-fail">{errorMsg}</div> : null}
        <div className="btn-login-wrapper">
          <button className="btn-login" type="submit">
            כניסה
          </button>
        </div>
      </form>
      {loading ? <Spinner /> : null}
      <div>
        <img className="logo" src={logo} />
      </div>
    </div>
  )
}
