import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const getOrdersData = createAsyncThunk(
  "barcodeScanner/getOrdersData",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_KEY}/Scanner/GetOrdersData/${params.searchTerms}`,
        {
          headers: {
            Authorization: `Bearer ${params.token}`,
          },
        }
      )
      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const barcodeScannerSlice = createSlice({
  name: "barcodeScanner",
  initialState: {
    isLoading: false,
    showScanModal: false,
    getOrdersDataSuccess: false,
    getOrdersDataFailed: false,
    ordersData: [],
  },
  reducers: {
    setShowScanPage: (state, { payload }) => {
      state.showScanModal = payload
      state.getOrdersDataSuccess = false
      state.getOrdersDataFailed = false
    },
    clearScanData: (state) => {
      state.getOrdersDataSuccess = false
      state.getOrdersDataFailed = false
      state.ordersData = []
    },
  },
  extraReducers: {
    [getOrdersData.pending]: (state) => {
      state.isLoading = true
      state.getOrdersDataSuccess = false
      state.getOrdersDataFailed = false
      state.ordersData = []
    },
    [getOrdersData.fulfilled]: (state, { payload }) => {
      state.getOrdersDataSuccess = true
      state.ordersData = payload
      state.isLoading = false
    },
    [getOrdersData.rejected]: (state, { payload }) => {
      state.isLoading = false
      state.getOrdersDataFailed = true
    },
  },
})

export const { setShowScanPage, clearScanData } = barcodeScannerSlice.actions

export default barcodeScannerSlice.reducer
