import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const loginUser = createAsyncThunk(
  "login/loginUser",
  async (userInput, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_KEY}/Login`,
        userInput,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const getUserData = createAsyncThunk(
  "login/getUserData",
  async (token, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_KEY}/User/${token}`
      )

      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const loginSlice = createSlice({
  name: "login",
  initialState: {
    loading: false,
    loginSuccess: false,
    loginFail: false,
    userloginData: null,
    userData: null,
    getUSerSuccess: false,
    getUSerFail: false,
    userToken: null,
  },
  reducers: {
    clearUserData: (state, { payload }) => {
      state.userData = null
      state.loginSuccess = false
      state.loginFail = false
      state.userloginData = null
      state.getUSerSuccess = false
      state.getUSerFail = false
      state.loading = false
    },
  },
  extraReducers: {
    [loginUser.pending]: (state) => {
      state.loading = true
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      // axios.defaults.headers.common["Authorization"] = `Bearer ${payload.token}`
      state.loading = false
      state.loginSuccess = true
      state.loginFail = false
      state.userloginData = payload
    },
    [loginUser.rejected]: (state) => {
      state.loading = false
      state.loginFail = true
      state.loginSuccess = false
    },
    [getUserData.pending]: (state) => {
      state.loading = true
    },
    [getUserData.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.getUSerSuccess = true
      state.userData = payload
    },
    [getUserData.rejected]: (state) => {
      state.loading = false
      state.getUSerFail = false
    },
  },
})

export const { clearUserData } = loginSlice.actions

export default loginSlice.reducer
