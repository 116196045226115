import { createSlice } from "@reduxjs/toolkit"
import { getCurrentIsraelTimeUnixFormat } from "../../utils/formatDate.js"

export const driverPageSlice = createSlice({
  name: "main",
  initialState: {
    testLatitude: null,
    testLongitude: null,
    startDayTime: null,
    currentLanguage: "He",
  },
  reducers: {
    setTestLat: (state, action) => {
      state.testLatitude = action.payload
    },
    setTestLong: (state, action) => {
      state.testLongitude = action.payload
    },
    setStartDayTime: (state, action) => {
      state.startDayTime = getCurrentIsraelTimeUnixFormat()
    },
    setLanguage: (state, action) => {
      state.currentLanguage = action.payload
    },
  },
})

export const { setTestLat, setTestLong, setStartDayTime, setLanguage } =
  driverPageSlice.actions

export default driverPageSlice.reducer
